import React from 'react'
import './SliderProjects.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules'

const SliderProjects = ({
  imagem1,
  imagem2,
  imagem3,
  imagem4,
  imagem5,
  titulo,
  texto,
  alt1,
  alt2,
  alt3,
  alt4,
  alt5
}) => {
  return (
    <div className="slider-projects">
      <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false
        }}
        pagination={{
          clickable: true
        }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        className="SwiperProjects"
      >
        <SwiperSlide>
          <img src={imagem1} alt={alt1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={imagem2} alt={alt2} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={imagem3} alt={alt3} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={imagem4} alt={alt4} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={imagem5} alt={alt5} />
        </SwiperSlide>
      </Swiper>
      <div className="slider-projects-content">
        <h2 className="slider-projects-title">{titulo}</h2>
        <p className="slider-projects-text">{texto}</p>
      </div>
    </div>
  )
}

export default SliderProjects
