import React from 'react'
import './Second.css' // Importe o arquivo de estilos

function Second(props) {
  return (
    <div className="second-container">
      <div className="second-image-container">
        <img className="second-image" src={props.image} alt="Imagem Grande" />
      </div>
      <div className="second-text-container">
        <h1 className="second-title">{props.title}</h1>
        <p className="second-description">{props.text}</p>
      </div>
    </div>
  )
}

export default Second
