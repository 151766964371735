import './Slider.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules'
import Clinica from '../../imagens/Imagens01/CasaF/HALLSUPERIOR/Hall-superior.png'
import Relva from '../../imagens/Imagens01/CasaF/HALLSUPERIOR/Hall-superior.png'
import Divas from '../../imagens/Imagens01/CasaF/HALLSUPERIOR/Hall-superior.png'
import Equolibrio from '../../imagens/Imagens01/CasaF/HALLSUPERIOR/Hall-superior.png'

const Slider = () => {
  return (
    <div className="slider">
      <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false
        }}
        pagination={{
          clickable: true
        }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={Clinica} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Relva} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Equolibrio} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Divas} />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Slider
