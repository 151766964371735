import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';


export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'Fale com a gente',
    path: '/',
    icon: <IoIcons.IoIosContact />,
    cName: 'nav-text'
  },
    {
      title: 'Projetos',
      icon: <AiIcons.AiFillProject />,
      cName: 'nav-title'
    },
    {
      title: 'Casa na Relva',
      path: '/casa-na-relva',
      cName: 'nav-text-projects'
    },
    {
      title: 'Casa F',
      path: '/casa-f',
      cName: 'nav-text-projects'
    },
    {
      title: 'Divas Jaleco',
      path: '/divas-jaleco',
      cName: 'nav-text-projects'
    },
    {
      title: 'Equolibrio',
      path: '/equolibrio',
      cName: 'nav-text-projects'
    },
    {
      title: 'Hospital',
      path: '/hospital-prontocordis',
      cName: 'nav-text-projects'
    },
    {
      title: 'Hotel',
      path: '/hotel-aurora',
      cName: 'nav-text-projects'
    },
    {
      title: 'Sthe Clinic',
      path: '/clinica-sthe',
      cName: 'nav-text-projects'
    },
    {
      title: 'Casa Be',
      path: '/casa-be',
      cName: 'nav-text-projects'
    },
  ];