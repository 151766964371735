import React from 'react'
import Footer from '../../componentes/footer/Footer'
import TecInfo from '../../componentes/categorias/first-tecnical-info/TecInfo'
import ImagemInteriores from '../../imagens/Imagens01/CasaF/HALLSUPERIOR/Hall-superior.png'
import Header from '../../componentes/header/Header'
import First from '../../componentes/categorias/first-project/First'
import Second from '../../componentes/categorias/second-project/Second'
import Third from '../../componentes/categorias/third-project/Third'
import Sidebar from '../../componentes/sidebar/Sidebar'

const Interiores = () => {
  return (
    <div className="interiores">
      <Header />
      <Sidebar/>
      <TecInfo
        imagemSrc={ImagemInteriores}
        textoTecnico="Texto técnico do projeto"
      />
      <First
        titulo={'Projeto Casa na Relva - Porto Velho/ RO'}
        texto={
          'O Hospital Prontocordis é um projeto de arquitetura inovador e moderno que busca proporcionar cuidados médicos de qualidade e promover o bem-estar dos pacientes. Localizado em um terreno amplo e de fácil acesso, o hospital se destaca por sua estrutura arquitetônica que combina funcionalidade, eficiência e um ambiente acolhedor.'
        }
        imagemSrc={ImagemInteriores}
      />
      <Second
        image={ImagemInteriores}
        title={'Wellbeing- Seeking Connections with Nature'}
        text={
          'Throughout the EBRD’s new workplace, the design seeks to connect occupants with nature. Many elements of biophilic design are employed to enhance staff wellbeing, including natural materials, patterns of nature, geographical references to the sweeping curves of the Thames in joinery details, and over 3000 trees and plants across the building. Reflecting the idea of a pathway through nature, the staircase is lined with continuous timber paneling and curves around a single, centrally placed tree. The layout of the workplace floors fosters a culture of choice, collaboration, and innovation, while signage and environmental graphics reflect organic shapes and the movement of water.'
        }
      />
      <Third
        image={ImagemInteriores}
        title={'Sustainability'}
        text={
          'Ensuring that the fit-out is net zero carbon was central to both the project’s design principles and the EBRD’s firmwide offsetting strategy, which meant the design and construction phase of the fit-out prioritised circularity, sourcing responsible materials, and reducing emissions wherever possible. The design of the building retains a strong focus on performance not just in terms of energy and whole life carbon, but also with regards to air, water, acoustics, and lighting quality, which will be continually verified in the operational facility.'
        }
      />
      <Footer />
    </div>
  )
}

export default Interiores
