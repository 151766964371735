import './Sidebar.css'
import * as FaIcons from 'react-icons/fa';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import * as AiIcons from 'react-icons/ai';
import { SidebarData } from './SidebarData';
import { IconContext } from 'react-icons';

    
function Sidebar() {

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
        <IconContext.Provider value={{ color: 'var(--black)' }}>
        <div className='navbar'>
            <Link to="#" className='menu-bars'>
                <FaIcons.FaBars onClick={showSidebar} className='menu-direita' />
            </Link>
        </div>

        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
            <ul className='nav-menu-items' onClick={showSidebar}>
                <li className='navbar-toggle'>
                    <Link to='#' className='menu-bars'>
                        <AiIcons.AiOutlineClose />
                    </Link>
                </li>
                {SidebarData.map((item, index) => {
                    return (
                        <li key={index} className={item.cName}>
                            <Link to={item.path}>
                            {item.icon}
                        <span>{item.title}</span>
                            </Link>
                        </li>
                    );
            })}
            </ul>
        </nav>
        </IconContext.Provider>
    
    </>

  )
}

export default Sidebar