import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './pages/homepage/HomePage'
import Interiores from './pages/interiores/Interiores'
import Hospital from './pages/projetos/hospital-prontocordis/Hospital'
import Design from './pages/design/Design'
import Arquitetura from './pages/arquitetura/Arquitetura'
import Relva from './pages/projetos/casa-na-relva/Relva'
import Hotel from './pages/projetos/hotel-aurora/Hotel'
import Be from './pages/projetos/be-casa/Be'
import Sthe from './pages/projetos/sthe-clinic/Sthe'
import CasaF from './pages/projetos/casa-f/CasaF'
import DivasJaleco from './pages/projetos/divas-jaleco/DivasJaleco'
import Equolibrio from './pages/projetos/equolibrio/Equolbrio'

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/interiores" element={<Interiores />} />
        <Route path="/design" element={<Design />} />
        <Route path="/arquitetura" element={<Arquitetura />} />
        <Route path="/casa-na-relva" element={<Relva />} />
        <Route path="/hospital-prontocordis" element={<Hospital />} />
        <Route path="/hotel-aurora" element={<Hotel />} />
        <Route path="/casa-be" element={<Be />} />
        <Route path="/clinica-sthe" element={<Sthe />} />
        <Route path="/casa-f" element={<CasaF />} />
        <Route path="/divas-jaleco" element={<DivasJaleco />} />
        <Route path="/equolibrio" element={<Equolibrio />} />
      </Routes>
    </Router>
  )
}
