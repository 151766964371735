import React from 'react'
import './NavProjects.css'
import { Link } from 'react-router-dom'

const NavProjects = ({ imageUrl, text, alt, link }) => {
  return (
    <div className="nav-project">
      <div className="project">
        <Link to={link}>
          <img src={imageUrl} alt={alt} className="project-image" />
        </Link>

        <div className="project-text">{text}</div>
      </div>
    </div>
  )
}

export default NavProjects
