import React from 'react'
import './QuemSomos.css'

const QuemSomos = () => {
  return (
    <div className="quem-somos">
      <div className="quem-somos-content">
        <h3 className="quem-somos-title">Quem somos nós</h3>
        <p className="quem-somos-text">
          Somos um escritório de arquitetura em constante busca pela harmonia
          estética, espacial e técnica de nossos projetos. Nossa equipe é
          formada nas mais recentes tecnologias do mercado e possui experiência
          em áreas de arquitetura e interiores e que compartilham seus
          conhecimentos a fim de criar espaços únicos que sejam o reflexo da
          satisfação das pessoas. Desenvolvemos arquitetura empática, onde
          priorizamos a satisfação e o bem estar de cada usuário e aplicamos
          nossa arquitetura e nossas técnicas adaptadas às ideias de cada
          cliente.
        </p>
      </div>
    </div>
  )
}

export default QuemSomos
