// components/banner/Banner.js
import React from 'react'
import './Banner.css'

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-content">
        <p className="banner-text">Amamos o que fazemos</p>
      </div>
    </div>
  )
}

export default Banner
