import React from 'react'
import './Header.css'
import Logo from '../../imagens/icones/logo-arqduq.png'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <header className='Header'>
            <div className='Header-Content'>
                <Link to='/'>
                <img src={Logo} alt={Logo} className="Header-Logo" ></img>
                </Link>
            </div>
        </header>
    )
  }
  
  export default Header
