import React from 'react'
import './First.css'
import Instagram from '../../../imagens/icones/icone-instagram.png'
import Linkedin from '../../../imagens/icones/icone-linkedin.png'

const First = ({ titulo, texto, imagemSrc }) => {
  return (
    <div className="first">
      <div className="left-content">
        <h2 className="title-first">{titulo}</h2>
        <p className="text-first">{texto}</p>
        <div className="additional-info">
          <p className="additional-text">Fale com a gente</p>
          <hr />
          <div className="icons">
             <a href="#" className="icon-link">
            <img src={Linkedin} alt="Linkedin" className="icons-first-img" />
          </a>
          <a href="#" className="icon-link">
            <img src={Instagram} alt="Instagram" className="icons-first-img" />
          </a>
          </div>
        </div>
      </div>
      <div className="right-content">
        <img src={imagemSrc} className="img-right-content" alt="Imagem" />
      </div>
    </div>
  )
}

export default First
