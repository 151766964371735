import React from 'react'
import './Third.css' // Importe o arquivo de estilos

function Third(props) {
  return (
    <div className="third-container">
      <div className="third-image-container">
        <img src={props.image} className="third-image" alt="Imagem Terceira" />
      </div>
      <div className="third-content">
        <div className="third-text">
          <h1 className="third-title">{props.title}</h1>
          <p className="third-description">{props.text}</p>
        </div>
      </div>
    </div>
  )
}

export default Third
