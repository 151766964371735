import React from 'react'
import './HomePage.css'
import NavProjects from '../../componentes/nav-projects/NavProjects'
import Slider from '../../componentes/slider/Slider'
import imagem1 from '../../imagens/Imagens01/CasaF/HALLSUPERIOR/Hall-superior.png'
import Divas from '../../imagens/Imagens01/CasaF/HALLSUPERIOR/Hall-superior.png'
import Equolibrio from '../../imagens/Imagens01/CasaF/HALLSUPERIOR/Hall-superior.png'
import Footer from '../../componentes/footer/Footer'
import AboutUs from '../../componentes/more-about-us/AboutUs'
import Banner from '../../componentes/banner/Banner'
import QuemSomos from '../../componentes/quem-somos/QuemSomos'
import Header from '../../componentes/header/Header'
import Sidebar from '../../componentes/sidebar/Sidebar'

const HomePage = () => {
  return (
    <div>
      <Header />
      <Sidebar />
      <Slider />
      <Banner />
      <nav className="navprojects-home">
        <NavProjects
          imageUrl={imagem1}
          text={'Interiores'}
          link={'/interiores'}
          alt={'Sala branca com móveis marrom'}
        />
        <NavProjects
          imageUrl={Divas} 
          text={'Exteriores'} 
          link={'/arquitetura'}
          alt={'Sala branca com móveis marrom'}
        />
        <NavProjects
          imageUrl={Equolibrio} 
          text={'Em Breve'} 
          link={'/designs'}
          alt={'Casa na rua '}
        />
      </nav>
      <QuemSomos />
      <AboutUs />
      <Footer />
    </div>
  )
}

export default HomePage
