import React from 'react'
import './SaberMais.css'

const SaberMais = ({ title, buttonText }) => {
  return (
    <div className="saber-mais">
      <hr className="saber-mais-line" />
      <h2 className="saber-mais-title">{title}</h2>
      <button className="saber-mais-button">{buttonText}</button>
    </div>
  )
}

export default SaberMais
