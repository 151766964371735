import React from 'react'
import './TecInfo.css'

const TecInfo = ({ imagemSrc }) => {
  return (
    <div className="tec-info">
      <img
        src={imagemSrc}
        alt="Informações Técnicas"
        className="tec-info-image"
      />
      <hr className="tec-info-line" />
    </div>
  )
}

export default TecInfo
