import React from 'react'
import './AboutUs.css'
import Hall from '../../imagens/Imagens01/CasaF/HALLSUPERIOR/Hall-superior.png'

const AboutUs = () => {
  return (
    <div className="about-us">
      <img src={Hall} className="about-us-image" alt="Foto de alguma coisa" />
      <div class="text-container">
        <div class="about-us-title">Mais sobre nós</div>
        <div class="about-us-text">
          Somos um escritório de Arquitetura localizado em Rondônia,
          especialistas em exteriores e interiores, somos inteligentes e
          tentamos fazer sempre o melhor com amor e dedicação.
        </div>
      </div>
    </div>
  )
}

export default AboutUs
