import React from 'react'
import './Footer.css'
import Instagram from '../../imagens/icones/icone-instagram.png'
import Linkedin from '../../imagens/icones/icone-linkedin.png'
import Logo from '../../imagens/icones/logo-arqduq.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
        <img src={Logo} alt={Logo} ></img>
        </div>
        <div className="footer-contact">
          <a className="footer-email">contato@arqduq.com</a>
          <a className="footer-phone">+1 (123) 456-7890</a>
        </div>
        <div className="footer-social-icons">
          <a href="#" className="footer-icon-link">
            <img src={Instagram} alt="Instagram" className="icons-footer-img" />
          </a>
          <a href="#" className="footer-icon-link">
            <img src={Linkedin} alt="Linkedin" className="icons-footer-img" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
