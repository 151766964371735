import React from 'react'
import SliderProjects from '../../../componentes/slider-projects/SliderProjects'
import Footer from '../../../componentes/footer/Footer'
import Imagem1 from '../../../imagens/Imagens01/CasaF/HALLSUPERIOR/Hall-superior.png'
import Imagem2 from '../../../imagens/Imagens01/CasaF/HALLSUPERIOR/Hall-superior.png'
import SaberMais from '../../../componentes/saber-mais-projects/SaberMais'
import Header from '../../../componentes/header/Header'
import Sidebar from '../../../componentes/sidebar/Sidebar'

const DivasJaleco = () => {
  return (
    <div className="divas-jaleco">
      <Header />
      <Sidebar/>
      <SliderProjects
        imagem1={Imagem2}
        imagem2={Imagem2}
        imagem3={Imagem1}
        imagem4={Imagem2}
        imagem5={Imagem1}
        titulo={'Hospital Prontocordis - Porto Velho/RO'}
        texto={
          'O Hospital Prontocordis é um projeto de arquitetura inovador e moderno que busca proporcionar cuidados médicos de qualidade e promover o bem-estar dos pacientes. Localizado em um terreno amplo e de fácil acesso, o hospital se destaca por sua estrutura arquitetônica que combina funcionalidade, eficiência e um ambiente acolhedor.'
        }
      />
      <SaberMais
        title={
          'Quer saber mais sobre o proje Clique no botão abaixo e fale conosco!'
        }
        buttonText={'Whatsapp'}
      />
      <Footer />
    </div>
  )
}

export default DivasJaleco
